import * as React from "react";
import { motion } from "framer-motion"
import { AnimatePresence } from "framer-motion";

const Animated = ({ children, key, windowThreshold=400, variants }) => {
    const shouldAnimate = window.innerWidth > windowThreshold;

    return (
        <AnimatePresence
            mode="wait"
            key={key}
        >
            <motion.div
                variants={
                    variants 
                    ? variants 
                    : {
                        initial: {opacity: 0},
                        animate: {opacity: 1},
                        exit: {opacity: 0}
                    }
                }
                initial={shouldAnimate ? "initial" : "hidden"}
                animate={shouldAnimate ? "animate" : "hidden"}
                exit={shouldAnimate ? "animate" : "hidden"}
                transition={{duration: 1}}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );

};

export default Animated;