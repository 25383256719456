import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, onValue, ref, set} from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref as sRef, getDownloadURL, listAll } from "firebase/storage";
import { useState, useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBlglZ87P47FxAuF__-srCEeKp6-l5KiXQ",
  authDomain: "yk-personal-website.firebaseapp.com",
  databaseURL: "https://yk-personal-website-default-rtdb.firebaseio.com",
  projectId: "yk-personal-website",
  storageBucket: "yk-personal-website.appspot.com",
  messagingSenderId: "316764192733",
  appId: "1:316764192733:web:2f44f182c739c139228034",
  measurementId: "G-E1M18JEV5J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logAnalytic = (eventName) => {
  logEvent(analytics, eventName);
};

// Initialize Importable Cloud Functions
const functions = getFunctions(app);
export const severance = httpsCallable(functions, "severance");
export const getInstagramPosts = httpsCallable(functions, "getInstagramPosts");

// get database and storage
const database = getDatabase(app);
const storage = getStorage(app);

export default storage;

export const useData = (path, transform) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        const dbRef = ref(database, path);
        const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
        if (devMode) { console.log(`loading ${path}`); }
        return onValue(dbRef, (snapshot) => {
            const val = snapshot.val();
            if (devMode) { console.log(val); }
            setData(transform ? transform(val) : val);
            setLoading(false);
            setError(null);
        }, (error) => {
            setData(null);
            setLoading(false);
            setError(error);
        });
    }, [path, transform]);

    return [data, loading, error];
};

export const setData = (path, value) => (
  set(ref(database, path), value)
);

export const getFirebaseImage = async (path="", pathRef=undefined) => {
  const imageRef = pathRef !== undefined ? pathRef : sRef(storage, path);
  const url = await getDownloadURL(imageRef);
  return url;
  // getDownloadURL(imageRef)
  //     .then((url) => { return url; })
  //     .catch((error) => {
  //         // Handle any errors
  //         switch (error.code) {
  //             case 'storage/object-not-found':
  //               // File doesn't exist
  //               console.log("Storage media retrieval error: code 1");
  //               break;
  //             case 'storage/unauthorized':
  //               // User doesn't have permission to access the object
  //               console.log("Storage media retrieval error: code 2");
  //               break;
  //             case 'storage/canceled':
  //               // User canceled the upload
  //               console.log("Storage media retrieval error: code 3");
  //               break;
  //             case 'storage/unknown':
  //               // Unknown error occurred, inspect the server response
  //               console.log("Storage media retrieval error: code 4");
  //               break;
  //             default:
  //               console.log("GetFireBaseImage Error Code:", error.code);
  //           }
  //     });
};

export const getURLsFromRefs = async (path, setState) => {
  const pathRef = sRef(storage, path);
  const response = await listAll(pathRef);
  const refArray = await response.items;

  // to get sub-directories...
  // const subFolders = await response.prefixes;

  const urls = [];
  if (Array.isArray(refArray) && refArray.length !== 0) {
      for (let i = 0; i < refArray.length; i++) {
        const url = await getFirebaseImage("", refArray[i]);
        urls.push(url);
      }
  }

  setState(urls)

  return urls;
};