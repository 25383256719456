import * as React from "react";
import "../css/Artwork/Artwork.css";
import "../css/Artwork/create.css";
import artSlide1 from "../assets/images/artwork/art-slide-1.jpg";
import artSlide2 from "../assets/images/artwork/art-slide-2.jpg";
import artSlide3 from "../assets/images/artwork/art-slide-3.jpg";
import artSlide4 from "../assets/images/artwork/art-slide-4.jpg";
import artSlide5 from "../assets/images/artwork/art-slide-5.jpg";
import { getInstagramPosts, logAnalytic } from "../utilities/firebase";
import { CONSTANTS } from "../utilities/constants";
import { scrollToTop } from "../utilities/utilities";

// Create Banner ***************************************************************
const togCreate = () => {
    const btnCreate = document.querySelector('.btn-create');
    const banners = document.querySelectorAll('.banner');
    banners.forEach((banner) => {
        banner.classList.toggle('unseen');
    })
    btnCreate.classList.toggle('active');
}

// Artwork Loader Placeholder **************************************************
export const ArtworkLoader = () => {
    return (
        <div className="instagram-media">
            <div className="ft loading-header">
                loading posts &nbsp; <i className="fas fa-circle-notch fa-spin"></i>
            </div>
            <div className="instagram-posts">
                <div className="media-container img-placeholder">
                    <div id="spinner-icon">
                        <i className="fas fa-atom fa-4x fa-spin"></i>
                    </div>
                </div>
                <div className="media-container img-placeholder">
                    <div id="spinner-icon">
                        <i className="fas fa-yin-yang fa-4x fa-spin"></i>
                    </div>
                </div>
                <div className="media-container img-placeholder">
                    <div id="spinner-icon">
                        <i className="fas fa-sun fa-4x fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Modals **********************************************************************
export const ImageModal = ({ src, setModalContent }) => {
    const closeImageModal = (e) => {
        if (e.target.classList.contains('image-pop-up')) {
            setModalContent(null);
        }
    }
    return (
        <div className="image-pop-up open" onClick={closeImageModal}>
            <img src={src} alt="" className="full-img open"/>
        </div>
    );
}

const VideoModal = ({ src, setModalContent }) => {
    const closeVideoModal = (e) => {
        if (e.target.classList.contains('video-pop-up')) {
            setModalContent(null);
        }
    }
    return (
        <div className="video-pop-up open" onClick={closeVideoModal}>
            <video className="full-vid open" controls autoPlay preload="none">
                <source src={src} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

const CarouselModal = ({ album, index, setModalContent }) => {
    const closeCarousel = (e) => {
        if (e.target.classList.contains('carousel-pop-up')) {
            setModalContent(null);
        }
    }

    const leftClick = () => {
        if (index > 0) {
            setModalContent(<CarouselModal album={album} index={index-1} setModalContent={setModalContent}/>);
        }
    }

    const rightClick = () => {
        if (index < album.length-1) {
            setModalContent(<CarouselModal album={album} index={index+1} setModalContent={setModalContent}/>);
        }
    }

    const leftDisabled = index === 0 ? " disabled-btn" : "";
    const rightDisabled = index === album.length-1 ? " disabled-btn" : "";
    const carouselMedia = album[index].media_type === 'IMAGE' ? 
                        <img src={album[index].media_url} alt="" className="full-car open"/> :
                        <video className="full-car open" controls autoPlay preload="none">
                            <source src={album[index].media_url} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>;
    return (
        <div className="carousel-pop-up open" onClick={closeCarousel}>
            <div className="carousel-media" data-album-index={index} data-album={album}>
                {carouselMedia}
            </div>
            <div className="car-navigator">
                <button className={"car-btn car-left-btn"+leftDisabled} onClick={leftClick}>
                    <i className="fas fa-chevron-left fa-2x"></i>
                </button>
                <button className={"car-btn car-right-btn"+rightDisabled} onClick={rightClick}>
                    <i className="fas fa-chevron-right fa-2x"></i>
                </button>
            </div>
        </div>
    );
}

// Instagram Data Handling *****************************************************
const Post = ({ item, album, setModalContent }) => {
    const openModal = () => {
        let modal;
        switch (item.media_type) {
            case 'IMAGE':
                modal = <ImageModal src={item.media_url} setModalContent={setModalContent} key={item.timestamp}/>;
                break;
            case 'VIDEO':
                modal = <VideoModal src={item.media_url} setModalContent={setModalContent} key={item.timestamp}/>;
                break;
            case 'CAROUSEL_ALBUM':
                modal = <CarouselModal album={album} index={0} setModalContent={setModalContent} key={item.timestamp}/>;
                break;
            default:
        }
        setModalContent(modal);
    }

    return (
        <div className="post" onClick={openModal}>
            <div className={`media-container ${item.media_type} post-${item.id}`}>
                <div className="modal-cover">
                    <i className="fas fa-expand" id="expand-icon"></i>
                    <i id="timestamp">{timestampHandler(item.timestamp)}</i>
                </div>
                <img src={item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM' ? item.media_url : item.thumbnail_url} 
                alt="" 
                data-original={item.media_url}
                data-album={item.media_type === 'CAROUSEL_ALBUM' ? JSON.stringify(album) : ""}/>
            </div>
        </div>
    );
}

const insertPosts = async (storedPosts, setArtworkData) => {
    const response = await getInstagramPosts();
    const data = await response.data;

    if (JSON.stringify(data) === '{}' || Object.keys(data).length === 0) {
        console.log("Error: Artwork data from fetch is empty:", data);
        console.log("Defaulting to stored posts...")
        setArtworkData(storedPosts);
        return;
    } else if ("error" in data && data["error"] !== null) {
        console.log("Error:", data["error"]["message"]);
        console.log("Defaulting to stored posts...")
        setArtworkData(storedPosts);
        return;
    }
    
    setArtworkData(data);
}

const timestampHandler = (t) => {
    const date = new Date(t);
    return(
        ""+date.getDate()+
        "/"+(date.getMonth()+1)+
        "/"+date.getFullYear()
    );
}

const removeActiveClasses = (panels) => {
    panels.forEach(panel => {
        if (panel.classList.contains('active')) {
            panel.classList.remove('active');
        }
    })
}

const createPosts = (storedPosts, artworkData, setModalContent, recentUpdateID) => {

    // if there are new posts, save ID to database
    if (recentUpdateID !== artworkData.data[0].id) {

        let missingPostCount = 0;
        for (let i = 0; i++; i < artworkData.data.length) {
            if (artworkData.data[i].id !== storedPosts.recentUpdateID) {
                missingPostCount++;
            } else {
                break;
            }
        }

        console.log(
            "Artwork Store Update Required!", 
            `At least ${missingPostCount} posts are unaccounted for.`
        );
    }

    const posts = artworkData.data.map((item) => {
        return (
            <Post
                key={item.id} 
                item={item} 
                album={artworkData.albums[item.id]} 
                setModalContent={setModalContent}/>
        );
    });

    return posts.slice(0, 24);
}

const Artwork = ({ storedPosts, artworkData, setArtworkData, recentUpdateID }) => {
    const [modalContent, setModalContent] = React.useState(null);

    React.useEffect(() => {
        logAnalytic(CONSTANTS.ARTWORK_ANALYTIC_EVENT);
        scrollToTop();
    }, []);

    React.useEffect(() => {
        // Sliding Cards ***************************************************************
        const panels = document.querySelectorAll('.panel'); // puts all .panel into a node list
        panels.forEach((panel) => {
            panel.addEventListener('click', () => {
                removeActiveClasses(panels);
                panel.classList.add('active');
            })
        });
        
        // insert instagram posts if state is null 
        // and we haven't already tried
        if (artworkData === null) {
            console.log("loading instagram posts...");
            insertPosts(storedPosts, setArtworkData);
        } else {
            console.log("Art package is already present...");
        }
    }, []);

    return (
        <div className="artwork-wrapper">
            <div className="gradient-back-art">
                <div className="banner art-banner unseen">
                    <span>CREATE!</span>
                </div>

                <div className="banner reg-banner">
                    <span>CREATE!</span>
                </div>
                
                <div className="btn-container">
                    <div className="btn-create" onClick={togCreate}>
                        <div className="btn-circle"></div>
                    </div>
                </div>
            </div>

            <div className="section-wrapper">
                <div className="section section1">
                    <div className="s1-text">
                        <p><b>Top Picks:</b> Here are some of my favorite pieces. Click to expand an image.</p>
                    </div>
                    <div className="slide-container">
                        <div className="panel" style={{backgroundImage: `url(${artSlide1})`}}>
                            <h3>Sunflower</h3>
                        </div>
                        <div className="panel" style={{backgroundImage: `url(${artSlide2})`}}>
                            <h3>Bandaid Girl</h3>
                        </div>
                        <div className="panel active" style={{backgroundImage: `url(${artSlide3})`}}>
                            <h3>Citywide Sunset</h3>
                        </div>
                        <div className="panel" style={{backgroundImage: `url(${artSlide4})`}}>
                            <h3>Self Portrait Sketch</h3>
                        </div>
                        <div className="panel" style={{backgroundImage: `url(${artSlide5})`}}>
                            <h3>School Dogs</h3>
                        </div>
                    </div>
                </div>
                <div className="section section2">
                    <span className="s2-text">
                        <p><b>Recent posts</b> pulled from my &nbsp;
                            <a href="https://www.instagram.com/swordlinke/?hl=en" target="_blank" rel="noopener noreferrer"><nobr>instagram</nobr></a>&nbsp;
                            <a href="https://www.instagram.com/swordlinke/?hl=en" target="Blank">
                                <i className="fab fa-instagram" aria-hidden="true"></i>
                            </a>&nbsp; account (via API).
                        </p>
                    </span>
                </div>
                <div className="section section3">
                    <div className="s3-container">
                        {
                            artworkData === null
                            ? <ArtworkLoader/> 
                            : <div className="instagram-media">
                                <div className="instagram-posts">
                                    {createPosts(storedPosts, artworkData, setModalContent, recentUpdateID)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="modal">{modalContent}</div>

            <footer>
                <div className="ft"></div>
            </footer>
        </div>
    );
}

export default Artwork