import * as React from 'react';
import '../css/About.css'
import profileImage from '../assets/images/about/headerIMG.ARW';
import { CustomLink } from './NavBar';
import { logAnalytic } from '../utilities/firebase';
import { CONSTANTS } from '../utilities/constants';

const About = () => {
    React.useEffect(() => {
        logAnalytic(CONSTANTS.ABOUT_ANALYTIC_EVENT);
    }, []);

    return (
        <div className="about-container">
            <div className="header">
                <div className="gradient-back-about"></div>
                <div className="profile-container">
                    <div className="profile">
                        <div className="profile-image">
                            <img src={profileImage} loading="lazy" alt=""/>
                        </div>
                        <div className="profile-info">
                            <div className="intro">
                                <h1 id="first-name">Yemi</h1>
                                <h1 id="last-name">Kelani</h1><br/>
                            </div>

                            <p>I am currently a Backend Engineer at 
                                <a 
                                    style={{color: '#006699'}} 
                                    href="https://www.ibm.com/us-en" 
                                    target="_blank" rel="noopener noreferrer"> IBM</a>
                                . I have a <b>BS in Computer Science</b>&nbsp; and an 
                                <b> MS specializing in Artificial Intelligence</b> from the  McCormick School of 
                                Engineering at 
                                <a 
                                    style={{color: 'rgb(104, 076, 150)'}} 
                                    href="https://www.northwestern.edu/"
                                    target="_blank" rel="noopener noreferrer"> Northwestern University</a>
                                .<br/><br/>

                                This site was developed from scratch using <b>React</b>, <b>Express</b>, and <b>Node.js</b>. 
                                It employs a plethora of large-scale custom components and css. <br/><br/>
                                
                                I constantly look for ways in which I can improve my skills as a developer and am
                                always eager to learn new things.
                            </p>
                            
                            <ul className="s-contact">
                                <li id="ln"><a href="https://www.linkedin.com/in/yemi-kelani/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-2x"></i></a></li>
                                <li id="ig"><a href="https://www.instagram.com/swordlinke/?hl=en" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-2x"></i></a></li>
                                <li id="gt"><a href="https://github.com/yemi-kelani" target="_blank" rel="noopener noreferrer"><i className="fab fa-github fa-2x"></i></a></li>
                            </ul>

                            <ul className="a-contact">
                                <li id="em"><i className="fas fa-envelope"></i><a href="mailto:aak.development@gmail.com">aak.development@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="profile-shadow"></div>
            </div>

            <div className="spacer"></div>

            <footer className="about-footer">
                <div className="ft-text">
                    <h3>Thanks for stopping by...</h3>
                    <p>While you're here, why not check out some of my other&nbsp; 
                        <CustomLink to="/projects">projects</CustomLink>?
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default About