import * as React from "react";
import "../css/Projects.css";
import { generateCards } from "../utilities/utilities.js"
import * as DisplayComponents from "../components/DisplayComponents.js";
import { logAnalytic } from "../utilities/firebase.js";
import { CONSTANTS } from "../utilities/constants.js";
 
const Projects = ({data}) => {
    const [projectPageContent, setProjectPageContent] = React.useState(null);
    const [filters, setFilters] = React.useState([]);

    React.useEffect(() => {
        if (projectPageContent === null) {
            const cards = generateCards(data);
            setProjectPageContent(cards);
        }
        logAnalytic(CONSTANTS.PROJECTS_ANALYTIC_EVENT);
    }, []);

    React.useEffect(() => {
        if (filters.length === 0) {
            const cards = generateCards(data);
            setProjectPageContent(cards);
        } else {
            const filteredData = data.filter(
                project => project.tags.some(item => filters.includes(item)) || project.tools.some(item => filters.includes(item))
            );

            const cards = generateCards(filteredData);
            setProjectPageContent(cards);
        }
    }, [filters]);

    const handleFilterChange = (event) => {
        if (event.target.checked && !filters.includes(event.target.value)) {
            setFilters([...filters, event.target.value]);
        } else if (!event.target.checked && filters.includes(event.target.value)) {
            const itemIndex = filters.indexOf(event.target.value);
            const filterCopy = [...filters];
            filterCopy.splice(itemIndex, 1);
            setFilters(filterCopy);
        }
    };

    const filterTagOptions = [
        ["Artificial Intelligence", "A.I."],
        ["Computer Systems", "Computer Systems"],
        ["Game Development", "Game Development"],
        ["Fullstack", "Fullstack"],
        ["Frontend", "Frontend"],
        ["Backend", "Backend"],
        ["P5", "P5"],
    ]

    const filterLangOptions = [
        "python",
        "assembly",
        "react",
        "javascript",
        "C#",
    ]

    return(
        <div className="project-wrapper">
            <DisplayComponents.GradientBack color="veryberry-4" />
            <div id="projects-spacer"></div>

            <div className="project-filters">
                <form>
                    
                    <h4>Topic filters:</h4><hr/>
                    {
                        filterTagOptions.sort().map((tagSet, index) => {
                            const optionIdentifier = `project-tag-${index}`;
                            return (
                                <>
                                    <input
                                        onChange={(event) => {handleFilterChange(event)}} 
                                        type="checkbox" key={optionIdentifier}
                                        id={optionIdentifier} name={optionIdentifier} value={`${tagSet[1]}`}/>
                                    <label htmlFor={optionIdentifier}>&nbsp;{tagSet[0]}</label><br/>
                                </>
                            );
                        })
                    }

                    <h4>Language filters:</h4><hr/>
                    {
                        filterLangOptions.sort().map((lang, index) => {
                            const optionIdentifier = `project-lang-${index}`;
                            return (
                                <>
                                    <input
                                        onChange={(event) => {handleFilterChange(event)}} 
                                        type="checkbox" key={optionIdentifier}
                                        id={optionIdentifier} name={optionIdentifier} value={`${lang}`}/>
                                    <label htmlFor={optionIdentifier}>&nbsp;{lang}</label><br/>
                                </>
                            );
                        })
                    }

                </form>
            </div>

            <div className="project-card-container">
                <div className="card-content" style={{flexWrap: "wrap", flexDirection: "row"}}>
                    {projectPageContent}
                </div>
            </div>
        </div>
    );
};

export default Projects;