import * as React from "react";
import "../css/Loading.css"

const Loading = () => {
    return (
        <div className="loading-wrapper">
            <h1 style={{fontSize: "2em"}}>
                loading&nbsp; <i className="fa-solid fa-gear fa-spin"></i>
            </h1>
        </div>
    );
};

export default Loading;