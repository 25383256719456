import * as React from "react";
import { useParams } from "react-router-dom";
import * as DisplayComponents from "../components/DisplayComponents.js";
import * as dictionary from "../utilities/dictionary.json"
import { scrollToTop, processComponentData, getProjectMediaURL } from "../utilities/utilities";
import "../css/Blog.css";
import { logAnalytic } from "../utilities/firebase.js";
import { CONSTANTS } from "../utilities/constants.js";

const Vocabulary = ({ componentList }) => {
    const [vocabulary, setVocabulary] = React.useState({
        originalVocabulary: [],
        activeVocabulary: []
    });
    const [collapsed, setCollapsed] = React.useState(true);
    const [searchTerm, setSearchTerm] = React.useState("");

    const bannedTerms = [
        "default",
    ]

    React.useEffect(() => {
        const pageText = componentList
            .filter(component => component.type === "Text")
            .map(component => component.text.join(" "))
            .join(" ");

        try {
            const terms = [];
            for (const [term, definition] of Object.entries(dictionary)) {
                if (typeof definition !== "string") {
                    continue;
                }

                const termRXPattern = `${term.trim().replace(" ", "[\\s+-?]")}`;
                const termRX = new RegExp(termRXPattern, "i");
                if (termRX.test(pageText)) {
                    terms.push([term, definition]);
                }
            }

            const sortedVocabulary = terms.sort((a, b) => a[0].localeCompare(b[0]));
            setVocabulary({
                originalVocabulary: sortedVocabulary,
                activeVocabulary: sortedVocabulary
            });
        } catch (error) {
            console.log(`(BlogPost.js > Vocabulary) Error loading dictionary: ${error}`);
            return;
        }
    }, []);

    React.useEffect(() => {
        if (searchTerm === "") {
            setVocabulary(prevState => ({
                ...prevState,
                activeVocabulary: [...prevState.originalVocabulary]
            }));
            return;
        }

        if (searchTerm.length >= 2) {
            const searchMatches = [];
            const termRXPattern = `${searchTerm.replace(/[^a-zA-Z\d\s:]/g, "").trim().replace(" ", "[\\s+-?]")}`;
            const termRX = new RegExp(termRXPattern, "i");

            for (const [term, definition] of Object.entries(dictionary)) {
                if (bannedTerms.includes(term) || typeof definition !== "string") {
                    continue;
                }
                if (termRX.test(term + " " + definition)) {
                    searchMatches.push([term, definition]);
                }
            }

            const sortedMatches = searchMatches.sort((a, b) => a[0].localeCompare(b[0]));
            setVocabulary(prevState => ({
                ...prevState,
                activeVocabulary: sortedMatches
            }));
            setCollapsed(false);
        }

    }, [searchTerm]);

    const handleVocabularySearchChange = (event) => {

        // TODO: saftey check input

        setSearchTerm(event.target.value);
    };

    return (
        <div className="vocabulary-container" >
            <div className="vocabulary-search-bar">
                <span 
                    className="vocabulary-header" 
                    onClick={() => {setCollapsed(!collapsed)}}
                >
                    <i className={`fa-solid fa-2x ${collapsed ? "fa-angle-right" : "fa-angle-down"}`}></i>
                    <h3>Vocabulary</h3>
                </span>
                <input
                    className="vocabulary-search-box"
                    type="search"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleVocabularySearchChange}
                />
            </div>
            <div className="vocabulary-table-container">
                <table 
                    className="vocabulary-table"
                    style={
                        collapsed
                        ? {display: "none"}
                        : {}
                    }
                >
                    <thead>
                        <tr>
                            <th>Term</th>
                            <th>Definition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vocabulary.activeVocabulary.length
                            ? vocabulary.activeVocabulary.map(entry => {
                                const term = entry[0];
                                const definition = entry[1];

                                return (
                                    <tr className="vocabulary-table-row">
                                        <td className="vocabulary-term">{term}</td>
                                        <td className="vocabulary-definition">{definition}</td>
                                    </tr>
                                );
                            })
                            :<tr className="vocabulary-table-row">
                                <td className="vocabulary-term">Search Results</td>
                                <td className="vocabulary-definition" style={{color:"red"}}>{"No matches found..."}</td>
                            </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                        <td></td>
                        <td>© Copyright Yemi Kelani {new Date().getFullYear()}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

const BlogPost = ({ blogData, projectData }) => {
    const params = useParams();

    React.useEffect(()=>{
        if(typeof window?.MathJax !== "undefined"){
            // load equations with MathJax 
            window.MathJax.typeset()
        }
        scrollToTop();
        logAnalytic(`${CONSTANTS.BLOG_ANALYTIC_EVENT}_${params.id}`);
    },[]);

    const pageData = blogData[params.id]
    
    return (
        <div className="blog-wrapper">
            <div className="blog-content">
                <div className="blog-post">
                    <DisplayComponents.GradientBack
                        mini={true}
                    />

                    {
                        pageData.metadata.type === "Research Paper Summary"
                        ? <DisplayComponents.ResearchPaperTitle
                            id={pageData.metadata.info.id}
                            title={pageData.metadata.info.title}
                            authors={pageData.metadata.info.authors}
                            link={pageData.metadata.info.link}
                            src={
                                pageData.metadata.info.src && pageData.metadata.info.src !== ""
                                ? pageData.metadata.info.src.loadReady
                                    ? pageData.metadata.info.src.src
                                    : getProjectMediaURL(pageData.metadata.info.src.src)
                                : null
                            }
                            labels={pageData.metadata.info.labels}
                            lastUpdated={pageData.metadata.info.lastUpdated}
                            abstract={pageData.metadata.info.abstract}
                            relatedProjectData={
                                pageData.metadata.info?.relatedProjectID !== undefined
                                ? projectData[projectData.length - pageData.metadata.info.relatedProjectID - 2]
                                : undefined
                            }
                        />
                        : <></>
                    }

                    {
                        pageData.metadata.options.vocab 
                        ? <Vocabulary componentList={pageData.components}/>
                        : <></>
                    }

                <DisplayComponents.BackButton/>

                    {processComponentData(pageData)}
                </div>
            </div>
        </div>
    );
};

export default BlogPost;