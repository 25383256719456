import * as React from "react";
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';
import { TypeAnimation } from 'react-type-animation';
import { generateCards } from "../utilities/utilities.js"
import { CustomLink } from './NavBar';
import Animated from "./Animated.js";

import homeBackground from "../assets/images/home/ykBG.JPG";
import aboutme from "../assets/images/home/aboutme.jpg";
// import code from "../assets/images/home/code.PNG";
// import code from "../assets/images/home/flower_animation.gif";
import photo from "../assets/images/home/photo.jpg";
import art from "../assets/images/home/art.jpg";

const HomeHeroSection = ({numResearchSummaries, setDisplayHeroSection}) => {
    const navigate = useNavigate();

    const showMainSection = async () => {


        // Blur Background
        let load = 99;
        let elements = ["home-background"];
        const scale = (num, in_min, in_max, out_min, out_max,) => {
            return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }
        const blurElements = () => {
            load--;
            if (load<40) {
                clearInterval(interval);
            }
            
            // THIS LINE CAUSE CSS ISSUES (POSITION: FIXED). BLUR THE SPECIFIC COMPONENTS YOU WANT TO,
            // DON'T BLUR PARENT COMPONENTS USED IN OTHER PLACES OR ISSUES WILL ENSUE.
            // document.body.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;

            elements.forEach(item => {
                const element = document.querySelector(`.${item}`);
                if (element !== null && element !== undefined) {
                    element.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;
                }
            });
        };
        const interval = setInterval(blurElements, 15);

        // Change Home Page Content
        setDisplayHeroSection(false);
    }

    return (
        <Animated
            variants={{
                initial: {opacity: 0, x:"-100vw", y: "-50vh"},
                animate: {opacity: 1, x: "-50vw", y: "-50vh"},
                exit: {opacity: 0, x:"-100vw", y: "-50vh"}
            }}
            windowThreshold={960}
            key="classic-hero-container-animation"
        >
            <div className="classic-hero-container">
                <div className="type-container">
                    <span>Yemi Kelani&nbsp;</span>
                    <br/>
                    <TypeAnimation
                        sequence={[
                            "Developer",
                            1500,
                            "Artist",
                            1500,
                            "Photographer",
                            1500,
                            "Writer",
                            1500,
                        ]}
                        wrapper="span"
                        cursor={false}
                        repeat={Infinity}
                        speed={6}
                        deletionSpeed={5}
                        style={{ 
                            fontdisplay: "inline-block", 
                        }}
                    />
                    <span className="type-cursor"></span>
                </div>

                <p id="classic-hero-container-description">
                    Welcome! I developed this website from scratch using React, large-scale custom CSS, and more.
                </p>

                <button 
                    id="classic-hero-container-btn"
                    onClick={() => {showMainSection()}}
                >
                    <i class="fa-solid fa-rocket"></i>&nbsp; 
                    Explore
                </button>

                <hr id="classic-hero-container-divider"/>

                <div id="classic-hero-container-badges">
                    <div className="info-badge">
                        <div className="info-badge-header">
                            <span className="info-badge-icon">
                                <i class="fa-solid fa-2x fa-graduation-cap"></i>
                            </span>
                            <span className="info-badge-title">
                                <b>Master of Science</b>
                                <b>Bachelor of Science</b>
                            </span>
                        </div>

                        <span className="info-badge-text">
                            <p>
                                I hold degrees in Computer Science with a specialization in 
                                Artificial Intelligence from 
                                <caption>
                                    <a 
                                        // style={{color: "rgb(104, 076, 150)"}} 
                                        href="https://www.northwestern.edu/"
                                        target="_blank" rel="noopener noreferrer">
                                        Northwestern University
                                    </a> 
                                </caption>
                            </p>
                        </span>
                    </div>

                    <div className="info-badge">
                        <div className="info-badge-header">
                            <span className="info-badge-icon">
                                <i class="fa-solid fa-2x fa-code-branch"></i>
                            </span>
                            <span className="info-badge-title">
                                <b>Avid Academic</b>
                            </span>
                        </div>

                        <span className="info-badge-text">
                            <p>
                                I have read and summarized <b style={{color: "blue"}}>{numResearchSummaries} </b>
                                research papers related to the field of Artificial Intelligence.
                                <caption onClick={() => {navigate("/blog")}} >View Summaries</caption>
                            </p>
                        </span>
                    </div>

                    <div className="info-badge">
                        <div className="info-badge-header">
                            <span className="info-badge-icon">
                                <i class="fa-solid fa-2x fa-palette"></i>
                            </span>
                            <span className="info-badge-title">
                                <b>Creative at Heart</b>
                            </span>
                        </div>

                        <span className="info-badge-text">
                            <p>
                                I'm a creator with over a decade of experience in visual arts.
                                Feel free to view my work.
                                <caption onClick={() => {navigate("/artwork")}} >See More</caption>
                            </p>
                        </span>
                    </div>

                    <div className="info-badge">
                        <div className="info-badge-header">
                            <span className="info-badge-icon">
                                <i class="fa-solid fa-2x fa-code"></i>
                            </span>
                            <span className="info-badge-title">
                                <b>Intrepid Developer</b>
                            </span>
                        </div>

                        <span className="info-badge-text">
                            <p>
                                I challenge myself as a developer by taking on interesting projects in my free time.
                                <caption onClick={() => {navigate("/projects")}} >View Projects</caption>
                            </p>
                        </span>
                    </div>

                </div>
            </div>
        </Animated>
    );
};

const MediaCard = ({imagesrc, info, customBlock}) => {
    return (
        <div className="media-card">
            <div className="media-card-left">
                {info}
            </div>
            <div className="media-card-right">
                {
                    customBlock 
                    ? customBlock
                    : <img className="media-card-img" src={imagesrc} rel="noreferrer" alt=""/>
                }
            </div>
        </div>
    );
};

const MediaCarousel = ({contentArray, startIndex=0}) => {
    const [index, setCarouselIndex] = React.useState(startIndex);
    
    const numCards = contentArray.length;
    const nextCard = () => {
        if (index + 1 < numCards) {
            setCarouselIndex(index + 1);
        }
    };
    const prevCard = () => {
        if (index - 1 >= 0) {
            setCarouselIndex(index - 1);
        }
    };

    return (
        <div className="media-carousel-wrapper">
            <div className="media-card-container">
                {contentArray[index]}
                <div className="media-carousel-navigator">
                    <button 
                        className="media-carousel-btn" 
                        id="prev"
                        onClick={prevCard}
                        disabled={index === 0}
                    ><i className="fas fa-angle-double-left"></i></button>
                    <button 
                        className="media-carousel-btn" 
                        id="next"
                        onClick={nextCard}
                        disabled={index === numCards - 1}
                    ><i className="fas fa-angle-double-right"></i></button>
                </div>
            </div>
            <div className="progress-wrapper">
                <div className="progress-container">
                    <div className="progress" id="progress"></div>
                    {
                        // for each card, create and return a "progress-circle" div
                        Array.from(Array(contentArray.length).keys()).map((index) => {
                            return <div className={`progress-circle progress-circle-${index}`}></div>;
                        })
                    }
                </div>
            </div>
        </div>
    );
};

const MainSection = ({projectData}) => {
    const navigate = useNavigate();

    return(
        <React.Fragment>
            <Animated
                windowThreshold={960}
                key="home-page-cards"
            >
                <MediaCarousel
                    contentArray={[
                        <MediaCard
                            key="about-card"
                            imagesrc={aboutme}
                            info={
                                <>
                                    <div className="media-card-header">
                                        <i class="fa-solid fa-2x fa-address-card"></i>
                                        <h2>About</h2>
                                    </div>
                                    <p> I created this website, largely from scratch, in an 
                                        effort to sharpen my skills as a developer. <br/><br/>
                                        
                                        Computer Science has always fascinated me. There's a lot that I want to do with it, 
                                        and not nearly enough hours in the day.
                                    </p>
                                    <div className="media-sub-nav">
                                        <CustomLink to={'/about'}><span id="about-icon"><i className='fas fa-user-alt fa-2x'></i></span></CustomLink>
                                        <a id="em" href="mailto:aak.development@gmail.com"target="_blank" rel="noopener noreferrer"><i className="fas fa-envelope fa-2x"></i></a>
                                        <a id="ln" href="https://www.linkedin.com/in/yemi-kelani/"target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-2x"></i></a>
                                        <a id="gt" href="https://github.com/yemi-kelani"target="_blank" rel="noopener noreferrer"><i className="fab fa-github fa-2x"></i></a>
                                    </div>
                                </> 
                            }
                        />,
                        <MediaCard
                            key="project-card"
                            info={
                                <>
                                    <div className="media-card-header">
                                        <i class="fa-solid fa-2x fa-diagram-project"></i>
                                        <h2>Projects</h2>
                                    </div>
                                    <p>
                                        I often work on interestng computer science projects spanning
                                        multiple areas including Artificial Intelligence and Deep Learning.
                                        Here are some of my most recent projects.
                                    </p>
                                </>
                            }
                            customBlock={
                                <div id="mini-project-cards">
                                    <div className="projects-preview" style={{flexWrap: "wrap", flexDirection: "row", color: "black"}}>
                                        {generateCards(projectData, true)}
                                        <div 
                                            id="projects-preview-view-more"
                                        > 
                                            <span 
                                                title="View all projects"
                                                className="clickable-span"
                                                id="view-all-projects"
                                                onClick={() => {navigate("/projects")}}
                                            >View All&nbsp;&nbsp;<i className="fa-solid fa-border-all"></i></span>
                                        </div>
                                    </div>
                                </div>
                            }
                        />,
                        <MediaCard
                            key="art-card"
                            imagesrc={art}
                            info={
                                <>
                                    <div className="media-card-header">
                                        <i class="fa-solid  fa-2x fa-brush"></i>
                                        <h2>Artwork</h2>
                                    </div>
                                    <p>I've been creating visual art for over a decade now. 
                                        I always make an honest effort to incorporate creativity 
                                        into everything I do, and I wouldn't have it any other way.
                                    </p>
                                    <br/>
                                    <button className="media-card-btn" onClick={() => {navigate("/artwork")}}>
                                        View Artwork
                                    </button>
                                </> 
                            }
                        />,
                        <MediaCard
                            key="photo-card"
                            imagesrc={photo}
                            info={
                                <>
                                    <div className="media-card-header">
                                        <i class="fa-solid fa-2x fa-camera-retro"></i>
                                        <h2>Photography</h2>
                                    </div>
                                    <p>
                                        They say a picture is worth a thousand words, so I'll let my
                                        photography speak for itself.
                                    </p>
                                    <br/>
                                    <button className="media-card-btn" onClick={() => {navigate("/photography")}}>
                                        View Galleries
                                    </button>
                                </> 
                            }
                        />
                    ]}
                />
            </Animated>
        </React.Fragment>
    );
};

const Home = ({projectData, numResearchSummaries }) => {
    const [displayHeroSection, setDisplayHeroSection] = React.useState(true);

    const styleConstants = {
        bannerImageHeight: 600, //px
        buttonTextColor: "#4887b1",
        buttonBackgroundColor: "#84D2F6",
        borderRadiusLarger: "7px",
        bolderFont: "700"
    }

    React.useEffect(() => {
        // Reverse Blur Screen
        let load = 0;
        let elements = ["nav-container", "home-background", "classic-hero-container"];

        const scale = (num, in_min, in_max, out_min, out_max,) => {
            return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }

        const blurElements = () => {
            load++;
            if (load>99) {
                clearInterval(interval);
            }
            
            // THIS LINE CAUSE CSS ISSUES (POSITION: FIXED). BLUR THE SPECIFIC COMPONENTS YOU WANT TO,
            // DON'T BLUR PARENT COMPONENTS USED IN OTHER PLACES OR ISSUES WILL ENSUE.
            // document.body.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;

            elements.forEach(item => {
                const element = document.querySelector(`.${item}`);
                if (element !== null && element !== undefined) {
                    element.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;
                }
            });
        };
        
        const interval = setInterval(blurElements, 15);
    }, []);

    return (
        <div className="home-wrapper">
            <div className="home-container">

                <div className="home-background">
                    <img id="home-background-img" src={homeBackground} alt=""/>
                </div>

                {
                    displayHeroSection ? 
                    <HomeHeroSection
                        numResearchSummaries={numResearchSummaries}
                        setDisplayHeroSection={setDisplayHeroSection}
                    />
                    : <MainSection
                        projectData={projectData}
                    />
                }

                {/*

                <DisplayComponents.LeftRightComponent
                    leftComponent={
                        <DisplayComponents.Text
                            text={["lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."]}
                        />
                    }
                    rightComponent={
                        <div id="mini-project-cards">
                            <div className="projects-preview" style={{flexWrap: "wrap", flexDirection: "row"}}>
                                {generateCards(projectData, true)}
                                <div 
                                    id="projects-preview-view-more"
                                > 
                                    <span 
                                        title="View all projects"
                                        className="clickable-span"
                                        id="view-all-projects"
                                        onClick={() => {navigate("/projects")}}
                                    >View All&nbsp;&nbsp;<i className="fa-solid fa-border-all"></i></span>
                                </div>
                            </div>
                        </div>
                    }
                    shrinkLeft={true}
                /> 
                
                */}

            
            </div>
        </div>
    );
};
 
export default Home