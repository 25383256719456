import * as React from "react";
import { Link, useResolvedPath, useMatch, useLocation } from "react-router-dom";
import "../css/NavBar.css";

//images
import defaultLogo from '../assets/images/logos/flower.jpg';
import otherLogo from '../assets/images/logos/logo-other-250.png';

export const CustomLink = ({ to, children, ...props}) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true});

    return (
        <li 
            className={isActive ? "active" : ""} 
            style={{listStyleType: "none", display: "inline"}}
        >
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    );
}

const NavBar = () => {
    const [toggleActive, setToggleActive] = React.useState(false);

    const flipState = () => {
        setToggleActive(!toggleActive);
    };


    let logo = defaultLogo;
    let styleClass = "nav-wrapper";
    let buttonStyle = "native-nav-btn";
    let headerText = <p></p>;

    switch(useLocation().pathname) {
        case "/":
            buttonStyle = "home-nav-btn";
            break;
        case "/other":
            logo = otherLogo;
            styleClass = "heading-other";
            buttonStyle = "other-nav-btn";
            headerText = <p className="header-percent">find the scary numbers...</p>;
            break;
        case "/photography":
            styleClass = "heading-photography";
            buttonStyle =  "photography-nav-btn";
            break;
        default:
            break;
    }

    console.log("LOCATION:", useLocation().pathname);
    
    return (
        <div
            className={`${styleClass} ${toggleActive ? "nav-container-open" : "nav-container-closed" } nav-container`}
        >
            <div className="logo-wrapper">
                <Link to="/" className="logo-wrapper-inner" onClick={()=>{setToggleActive(false)}}>
                    <img className="logo" src={logo} aria-label="logo" alt="logo"/>
                    <h3 className="logo-text" translate="no">yemi</h3>
                </Link>
            </div>

            {headerText}

            <nav>
                <span className="nav-background"></span>
                <ul className="nav-links">
                    <CustomLink to={"/about"} onClick={()=>{setToggleActive(false)}}>about</CustomLink>
                    <CustomLink to={"/projects"} onClick={()=>{setToggleActive(false)}}>projects</CustomLink>
                    <CustomLink to={"/artwork"} onClick={()=>{setToggleActive(false)}}>artwork</CustomLink>
                    <CustomLink to={"/photography"} onClick={()=>{setToggleActive(false)}}>photography</CustomLink>
                    <CustomLink to={"/blog"} onClick={()=>{setToggleActive(false)}}>blog</CustomLink>
                    <CustomLink to={"/other"} onClick={()=>{setToggleActive(false)}}>other</CustomLink>
                </ul>
            </nav>

            <div className="cta">
                <Link to="/contact" className={`nav-btn ${buttonStyle}`}>contact</Link>
            </div>

            <button className="drop-tog" onClick={flipState}>
                <div className="tog">
                    {
                        toggleActive
                        ? <i className="fa-solid fa-rectangle-xmark"></i>
                        : <i className="fa-solid fa-bars"></i>
                    }
                </div>
            </button>
        </div>
    )
}

export default NavBar;