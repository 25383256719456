// project IDs to codebase

export const colors = {
    white: "white",
    black: "black"
}

export const codebase = {
    6: [
`import numpy as np
import pandas as pd

# preprocessing
from sklearn.model_selection import train_test_split
from sklearn.model_selection import cross_validate
from sklearn.feature_extraction.text import TfidfVectorizer

# models
from sklearn.experimental import enable_halving_search_cv 
from sklearn.model_selection import HalvingGridSearchCV
from sklearn.linear_model import LogisticRegression

from google.colab import drive
drive.mount('/content/drive')`,
`column_names = ["target", "ids", "date", "flag", "user", "text"]
data = pd.read_csv("/content/drive/MyDrive/EE 375 475 ML Project/trainingTwitter.csv", 
                    encoding="ISO-8859-1", names=column_names)

# reassign labels from [0,4] to [-1,1]
data["target"] = np.where(data["target"]==0, -1, 1) 
data.head()`,
`# create corpus
corpus = data["text"]
tfidf_vec = TfidfVectorizer(stop_words='english')

# encode via TFIDF
X = tfidf_vec.fit_transform(corpus)
y = data["target"]

# spilt data
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2, random_state=0)

# Logistic Regression
logistic_model = LogisticRegression(solver='saga')
logistic_model.fit(X_train, y_train)

# Cross Validation
cv = cross_validate(logistic_model, X_train, y_train, scoring="f1", cv=3)
score = cv['test_score'].mean()
print("Logisitic Regression CV Results: \n", 
      "average fit time:", cv['fit_time'].mean(), 
      "average test score:", score)

# Quality Metrics
accuracy = logistic_model.score(X_test, y_test)
print(f"Model accuracy: {accuracy}")`,
`import warnings
warnings.filterwarnings("ignore")

model = LogisticRegression()

parameters = {
  'max_iter': [1000],
  'penalty' : ['l1','l2'], 
  'C'       : [0.1, 1, 10, 100],
  'solver'  : ['liblinear', 'saga']
}

clf = HalvingGridSearchCV(model, param_grid=parameters, scoring='f1', cv=3)
clf.fit(X_train[:10000], y_train[:10000])
print("accuracy :", clf.best_score_)
print("best params :", clf.best_params_)

# Quality Metrics
accuracy = clf.score(X_test, y_test)
print(f"Model accuracy: {accuracy}")`,
`# Logistic Regression
batch_model = LogisticRegression(solver='saga', warm_start=True)

# create mini-batches
batches = []
num_batches = 10
batch_size = y_train.size // num_batches
for batch_number in range(num_batches):
  start = batch_number * batch_size
  end =  (batch_number+1) * batch_size
  batches.append((X_train[start:end], y_train[start:end]))

# train
for mini_x, mini_y in batches:
  batch_model.fit(mini_x, mini_y)

# Quality Metrics
accuracy = batch_model.score(X_test, y_test)
print(f"Model accuracy: {accuracy}")`
    ],
    3: [
`Vector3 prevSegmentPosition = transform.position;
//then move head
foreach (Snake segment in segments)
{
    Vector3 temp = segment.transform.position;
    segment.transform.position = prevSegmentPosition;
    prevSegmentPosition = temp;
}`,
`// The old way:
Snake newSeg = Instantiate(
snakePrefab, 
lastSegPosition, 
Quaternion.identity, 
gameObject.transform);
        
// The new way:     
Snake newSeg = Instantiate(snakePrefab);
newSeg.transform.position = lastSegPosition;`
    ]
}

// images in canvases must be placed in the "public" folder and
// the path to them must be relative to "index.js"
export const canvases = {
    2: [
        {
            t: null,
            pp: null,
            pct: null,
            angle: 0,
            flower: null,
            radius: null,
            loopTime: null,
            counter: 0,
            preload(p) {
                this.flower1 = p.loadImage('../p5/flower.png');
                this.flower2 = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(255, 255, 255);
    
            
                p.angleMode(p.DEGREES);
                this.loopTime = 4;
            },
            
            draw(p) {
                const fWIDTH = 30;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = (Math.PI * 2 * this.pp) % 360;
                this.counter++;
              
                const flr = this.counter > 240 ? this.flower1 : this.flower2;
                const pixel = this.counter > 240 ? 0 : 255;
                if (this.counter > 480) {
                  this.counter = 0;
                }
              
                p.background(pixel, pixel, pixel, 20);
                const flex = p.map(this.pct, 0, 1, 0, Math.PI)
                
                const theta = p.map(this.pct, 0, 1, 0, Math.PI)
                const x = (cCENTER) + 100*Math.sin(theta)
                const y = (cCENTER) + 100*Math.cos(theta)
                
                // 𝑆𝑥+𝑟cos(𝑡𝑠),𝐴𝑦=𝑆𝑦+𝑟sin(𝑡𝑠)
                
                // image
                p.push();
                p.translate(x, y);
                p.rotate(this.angle*10);
                p.imageMode(p.CENTER);
                p.image(flr, 0, 0, fWIDTH, fWIDTH, 0.1);
                p.pop();
            
                this.angle = (this.pct * 57) % 360
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            angle: 0,
            flower: null,
            radius: null,
            loopTime: null,
            counter: 0,
            preload(p) {
                this.flower1 = p.loadImage('../p5/flower.png');
                this.flower2 = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(255, 255, 255);
    
            
                p.angleMode(p.DEGREES);
                this.loopTime = 4;
            },
            
            draw(p) {
                const fWIDTH = 30;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
              
                p.background(255, 255, 255, 0.2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = (Math.PI * 2 * this.pp) % 360;
                this.counter++;
              
                const flr = this.counter > 240 ? this.flower1 : this.flower2;
                if (this.counter > 480) {
                  this.counter = 0;
                }
                
                const theta = p.map(this.pct, 0, 1, 0, Math.PI)
                const x = p.map(this.pct, 0, 1, -40, 40)
                const y = (p.height / 2) - Math.sin(theta) * p.height/2
                p.image(flr, x, y, fWIDTH, fWIDTH, 0.1);
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            angle: 0,
            flower: null,
            radius: null,
            loopTime: null,
            preload(p) {
                this.flower = p.loadImage('../p5/flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(255, 255, 255);
    
            
                p.angleMode(p.DEGREES);
                this.loopTime = 4;
            },
            
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
                
                p.push()
                p.translate(cCENTER-(fWIDTH/2),cCENTER-(fWIDTH/2));
                this.radius = this.pct * 600;
                p.fill(0, 0, 0)
                p.circle(fWIDTH/2, fWIDTH/2, this.radius);
                
                p.fill(255, 255, 255)
                p.circle(fWIDTH/2, fWIDTH/2, (5*this.radius)/6);
            
                p.fill(0, 0, 0)
                p.circle(fWIDTH/2, fWIDTH/2, (2*this.radius)/3);
            
                p.fill(255, 255, 255)
                p.circle(fWIDTH/2, fWIDTH/2, this.radius/2);
            
                p.fill(0, 0, 0)
                p.circle(fWIDTH/2, fWIDTH/2, this.radius/3);
            
                p.fill(255, 255, 255)
                p.circle(fWIDTH/2, fWIDTH/2, this.radius/6);
                p.pop()
            
                // image
                p.push();
                p.translate(cCENTER,cCENTER);
                p.rotate(this.angle);
                p.imageMode(p.CENTER);
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                p.pop();
            
                this.angle = (this.pct * 57) % 360
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            band: 1,
            angle: 0,
            flower: null,
            radius: 0,
            loopTime: null,
            preload(p) {
                this.flower = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(0, 0, 0);
    
                p.push();
                p.translate(cCENTER,cCENTER);
                p.imageMode(p.CENTER);
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                p.pop();
    
                p.textSize(fWIDTH / 2);
                // p.textAlign(cCENTER, 300);
        
                p.angleMode(p.DEGREES);
                this.loopTime = 2;
            },
            
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
                p.background(0,0,0)
                
                const band = (step) => {
                    p.push()
                    p.translate(cCENTER-(fWIDTH/2),cCENTER-(fWIDTH/2));
                    this.radius = (141.42 * this.pct)
                    this.bg = p.map(this.pct, 0, 2*Math.PI, 0, 255);
                    p.fill(this.bg, 0, 0)
                    p.circle(fWIDTH/2, fWIDTH/2, this.radius - (step * 50));
                    
                    p.fill(0, 0, 0)
                    p.circle(fWIDTH/2, fWIDTH/2, this.radius - ((step + 1) * 50));
                    p.pop()
                }
    
                band(this.band);
    
                // image
                p.push();
                p.translate(cCENTER,cCENTER);
                p.rotate(this.angle);
                p.imageMode(p.CENTER);
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                p.pop();
            
                this.angle = (this.pct * 57) % 360
                p.fill(255, 255, 255, this.bg);
                p.text("S.O.S.", 160, 350);
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            band: 1,
            angle: 0,
            noise: 0,
            flower: null,
            radius: 0,
            loopTime: null,
            preload(p) {
                this.flower = p.loadImage('../p5/flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(0, 0, 0);
    
                p.push();
                p.translate(cCENTER,cCENTER);
                p.imageMode(p.CENTER);
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                p.pop();
        
                p.angleMode(p.DEGREES);
                this.loopTime = 2;
            },
            
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // time
                this.t = p.millis() * 0.0008;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
    
                this.x = cWIDTH * p.noise(this.t);
                this.y = cWIDTH * p.noise(this.t + 100);
                
                // image
                p.push();
                p.translate(this.x,this.y);
                p.rotate(this.angle);
                p.imageMode(p.CENTER);
                this.bg = p.map(this.pct, 0, 2*Math.PI, 0, 255);
                p.tint((p.frameCount*this.pct), 200, (p.frameCount*(this.pct-Math.PI)), this.bg)
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                // p.tint(this.bg, this.bg, this.bg);
                p.tint((p.frameCount*10)%255, 80, 100, 80)
                p.pop();
            
                this.angle = (this.pct * 57) % 360
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            band: 1,
            angle: 0,
            noise: 0,
            flower: null,
            radius: 0,
            loopTime: null,
            preload(p) {
                this.flower = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(0, 0, 0);
    
                p.push();
                p.translate(cCENTER,cCENTER);
                p.imageMode(p.CENTER);
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                p.pop();
        
                p.angleMode(p.DEGREES);
                this.loopTime = 2;
            },
            
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // time
                this.t = p.millis() * 0.0008;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
    
                this.x = cWIDTH * p.noise(this.t);
                this.y = cWIDTH * p.noise(this.t + 100);
                
                // image
                p.push();
                p.translate(this.x,this.y);
                p.rotate(this.angle);
                p.imageMode(p.CENTER);
                this.bg = p.map(this.pct, 0, 2*Math.PI, 0, 255);
                p.tint((p.frameCount*this.pct), 200, (p.frameCount*(this.pct-Math.PI)), this.bg)
                p.image(this.flower, 0, 0, fWIDTH, fWIDTH);
                // p.tint(this.bg, this.bg, this.bg);
                p.tint((p.frameCount*10)%255, 80, 100, 80)
                p.pop();
            
                this.angle = (this.pct * 57) % 360
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            trans: null,
            flower1: null,
            flower2: null,
            preload(p) {
                this.flower1 = p.loadImage('../p5/flower.png');
                this.flower2 = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(253, 240, 110);
                this.loopTime = 4;
            },
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
                p.background(253, 240, 110);
    
                const flowers = (flr, x, dir) => {
                    p.push();
                    const y = dir * (this.trans + (this.pct * 48));
                    p.translate(x, cCENTER);
                    for (let i = 0; i < 5; i++) {
                        p.imageMode(p.CENTER);
                        p.image(flr, 0, y + ((i + 1) * 100), fWIDTH, fWIDTH);
                        p.image(flr, 0, y, fWIDTH, fWIDTH);
                        p.image(flr, 0, y - ((i + 1) * 100), fWIDTH, fWIDTH);
                    }
                    p.pop();
                }
    
                flowers(this.flower1, cCENTER, -1);
                flowers(this.flower1, cCENTER - 145, -1);
                flowers(this.flower1, cCENTER + 145, -1);
    
                flowers(this.flower2, cCENTER - 75, 1);
                flowers(this.flower2, cCENTER + 75, 1);
    
                flowers(this.flower2, cCENTER - 220, 1);
                flowers(this.flower2, cCENTER + 220, 1);
            }
        },
        {
            t: null,
            pp: null,
            pct: null,
            trans: null,
            flower1: null,
            flower2: null,
            preload(p) {
                this.flower1 = p.loadImage('../p5/flower.png');
                this.flower2 = p.loadImage('../p5/b-flower.png');
            },
            setup(p, canvasParentRef) {
                const fWIDTH = 60
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
                p.createCanvas(cWIDTH,cWIDTH).parent(canvasParentRef);
                p.background(253, 240, 110);
                this.loopTime = 4;
            },
            draw(p) {
                const fWIDTH = 60;
                const cWIDTH = 400;
                const cCENTER = (cWIDTH/2,cWIDTH/2);
            
                // circle
                this.t = p.millis() * 0.001;
                this.pp = (this.t % this.loopTime) / this.loopTime;
                this.pct = Math.PI * 2 * this.pp;
                p.background(253, 240, 110);
                
    
                const flowers = (flr, x, dir) => {
                    p.push();
                    const y = dir * (this.trans + (this.pct * 48));
                    p.translate(x, cCENTER);
                    for (let i = 0; i < 5; i++) {
                        p.imageMode(p.CENTER);
                        p.image(flr, 0, y + ((i + 1) * 100), fWIDTH, fWIDTH);
                        p.image(flr, 0, y, fWIDTH, fWIDTH);
                        p.image(flr, 0, y - ((i + 1) * 100), fWIDTH, fWIDTH);
                    }
                    p.pop();
                }
    
                flowers(this.flower2, cCENTER, -1);
                flowers(this.flower2, cCENTER - 145, -1);
                flowers(this.flower2, cCENTER + 145, -1);
    
                flowers(this.flower1, cCENTER - 75, 1);
                flowers(this.flower1, cCENTER + 75, 1);
    
                flowers(this.flower1, cCENTER - 220, 1);
                flowers(this.flower1, cCENTER + 220, 1);
            }
        }
    ]
}