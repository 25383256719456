import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { useData } from "./utilities/firebase.js";
// import { useLocation } from "react-router-dom";

// import * as database from "./utilities/database.json";

import './App.css';
import './css/DisplayComponents.css';
import NavBar from "./components/NavBar.js";
import Home from "./components/Home.js";
import About from "./components/About.js";
import Projects from "./components/Projects.js";
import Project from "./components/Project.js";
import Artwork from "./components/Artwork.js";
import Photography from "./components/Photography.js";
import Blog from "./components/Blog.js";
import Other from "./components/Other.js";
import Contact from "./components/Contact.js";
import Loading from "./components/Loading.js";
import BlogPost from "./components/BlogPost.js";

const App = () => {
  const [data, loadingData, errorData] = useData("/");
  const [artworkData, setArtworkData] = React.useState(null);
  // const location = useLocation();

  if (errorData) return <h1>{errorData}</h1>;
  if (loadingData) return <Loading/>;

  const blogData = data["blogs"];
  const projectData = data["projects"];
  const storedPosts = data["artworks"]["pages"][0];
  const recentUpdateID = data["artworks"]["recentUpdateID"];
  const numResearchSummaries = blogData.filter(blogPost => blogPost.metadata.type === "Research Paper Summary").length;

  return (
    <div>
        {/* {location.pathname !== "/" ? <NavBar/> : <></>} */}
        <NavBar/>
        <div className="app-content">
            <Routes>
              <Route path="/" element={<Home projectData={projectData.slice(0,3)} numResearchSummaries={numResearchSummaries}/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/projects" element={<Projects data={projectData}/>}/>
              <Route path="/projects/:id" element={<Project data={projectData}/>}/>
              <Route path="/artwork" element=
                {
                  <Artwork 
                    storedPosts={storedPosts} 
                    artworkData={artworkData} 
                    setArtworkData={setArtworkData}
                    recentUpdateID={recentUpdateID}
                  />
                }
              />
              <Route path="/photography" element={<Photography/>}/>
              <Route path="/blog" element={<Blog blogData={blogData}/>}/>
              <Route path="/blog/:id" element={<BlogPost blogData={blogData} projectData={projectData}/>}/>
              <Route path="/other" element={<Other/>}/>
              <Route path="/contact" element={<Contact/>}/>
            </Routes>
        </div>

        {/* <footer>© Copyright Yemi Kelani {new Date().getFullYear()}</footer> */}

    </div>
  );
}

export default App;