import React from "react";
import * as DisplayComponents from "../components/DisplayComponents.js";
import { InferenceSession, Tensor } from "onnxruntime-web";
import onnxModel from "../assets/models/TicTacToeAgent.onnx";
import "../css/TicTacToe.css";

const TicTacToe = ({ level }) => {
    const [session, setSession] = React.useState(null);
    const [board, setBoard] = React.useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [disabledBtns, setDisabledBtns] = React.useState(
        [false,false,false,false,false,false,false,false,false]
    );
    const [gameRecord, setGameRecord] = React.useState({
        gameWinner: null,
        gamesWonByPlayer: 0,
        gamesLostByPlayer: 0,
        gamesTied: 0
    });

    const player1 = -1; // X
    const player2 =  1; // O

    React.useEffect(() => {

        const initSession = async () => {
            // Create a session for the game
            const sess = await InferenceSession.create(onnxModel, {
                backendHint: "webgl",
                executionProviders: ["webgl"],
            });

            setSession(sess);

            // make the first move
            const boardCopy = [0, 0, 0, 0, 0, 0, 0, 0, 0]; // empty board
            agentMove(boardCopy, sess);
        };

        initSession();

    }, []);

    React.useEffect(() => {
        if (gameRecord.gameWinner) {
            setDisabledBtns(
                [true,true,true,true,true,true,true,true,true]
            );
        }
    }, [gameRecord]);

    const isGameOver = (boardCopy) => {

        // check rows and columns
        for (let i = 0; i < 3; i++) {
            if ((boardCopy[(i * 3) + 0] === boardCopy[(i * 3) + 1]) &&
                (boardCopy[(i * 3) + 1] === boardCopy[(i * 3) + 2]) &&
                (boardCopy[(i * 3) + 2] !== 0)) {
                return boardCopy[(i * 3) + 0];
            }  else if ((boardCopy[(0 * 3) + i] === boardCopy[(1 * 3) + i]) &&
                (boardCopy[(1 * 3) + i] === boardCopy[(2 * 3) + i]) &&
                (boardCopy[(2 * 3) + i] !== 0)) {
                return boardCopy[(0 * 3) + i];
            }
        }

        // check diagonals
        if ((boardCopy[0] === boardCopy[4]) &&
            (boardCopy[4] === boardCopy[8]) &&
            (boardCopy[8] !== 0)) {
            return boardCopy[0];
        } else if ((boardCopy[2] === boardCopy[4]) &&
            (boardCopy[4] === boardCopy[6]) &&
            (boardCopy[6] !== 0)) {
            return boardCopy[2];
        }

        // check if there's a tie
        const boardSum = boardCopy.map((value) => {
            return value === 0 ? 0 : 1;
        }).reduce((partialSum, x) => partialSum + x, 0);

        // return 0 if game is tied, return 2 if game is ongoing
        return boardSum === 9 ?  0 : 2;

    };

    const evaluateGameState = (boardCopy) => {
        const gameStatus = isGameOver(boardCopy);

        if (gameStatus === 2) {
            return false;
        } else if (gameStatus === player1) {
            setGameRecord({
                ...gameRecord,
                gameWinner: player1,
                gamesLostByPlayer: gameRecord.gamesLostByPlayer + 1
            });
            setBoard(boardCopy);
            return true;
        } else if (gameStatus === player2) {
            setGameRecord({
                ...gameRecord,
                gameWinner: player2,
                gamesWonByPlayer: gameRecord.gamesWonByPlayer + 1
            });
            setBoard(boardCopy);
            return true;
        } else if (gameStatus === 0) {
            setGameRecord({
                ...gameRecord,
                gameWinner: 0,
                gamesTied: gameRecord.gamesTied + 1
            });
            setBoard(boardCopy);
            return true;
        }
    }

    const agentMove = async (boardCopy, session) => {
        // Perform inference with input data
        const inputArray = new Float32Array(boardCopy);
        const inputTensor = new Tensor("float32", inputArray, [1, 9]);

        // when converting my pytorch model from pytorch to onnx, I
        // specified an input name "input" (although it can be whatever
        // string)... Thats why the object below has an "input" key
        const outputMap = await session.run({ input: inputTensor });

        // Again the key "output" exists because I specified it as an
        // output_name when I created the onnx file
        const qValues = outputMap["output"].data;

        // Mask the outputs and place agent one on the cell 
        // corresponding to highest q-value
        const maskedValues = boardCopy.map((value, index) => {
            if (value !== 0) {
                return -1000;
            } else {
                return qValues[index];
            }
        });

        const argmax = maskedValues.indexOf(Math.max(...maskedValues));

        // console.log("Model output:", qValues);
        // console.log("Masked output:", maskedValues);
        // console.log("argmax", argmax);

        // make agent move
        boardCopy[argmax] = player1;

        // set state
        setBoard(boardCopy);

        evaluateGameState(boardCopy);
    };

    const playerMove = (index) => {
        // console.log("Player Move Index:", index);
        const btns = [...disabledBtns];
        btns[index] = true;
        setDisabledBtns(btns);

        const boardCopy = [...board];
        boardCopy[index] = player2;

        const gameOver = evaluateGameState(boardCopy);
        if (!gameOver) {
            agentMove(boardCopy, session);
        }
    };

    const resetGame = () => {
        setBoard([0, 0, 0, 0, 0, 0, 0, 0, 0]);
        setGameRecord({
            ...gameRecord,
            gameWinner: null
        });
        setDisabledBtns(
            [false,false,false,false,false,false,false,false,false]
        );
    };

    return (
        <div className="tictactoe-wrapper">
            <div className="tictactoe-game">
                {
                    // wait for session to load
                    session !== null
                        ? <div className="tictactoe-grid">
                            {
                                Array.from(Array(3).keys()).map((rowIndex) => {
                                    return (
                                        <div className="tictactoe-row">
                                            {
                                                Array.from(Array(3).keys()).map((colIndex) => {
                                                    const index = (rowIndex * 3) + colIndex
                                                    return (
                                                        <button
                                                            disabled={disabledBtns[index]}
                                                            className="tictactoe-cell-btn"
                                                            onClick={(event) => {
                                                                // make player move in index
                                                                if (board[index] === 0) {
                                                                    playerMove(index);
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                board[index] === -1
                                                                    ? "X"
                                                                    : board[index] === 1
                                                                        ? "O"
                                                                        : ""
                                                            }
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        : <DisplayComponents.LoadingPlaceholder size="large" />
                }
            </div>
            <div className="tictactoe-text">

                {
                    gameRecord.gameWinner === -1
                        ? <h4 id="tictactoe-loss"> You Lost! </h4>
                        : gameRecord.gameWinner === 1
                            ? <h4 id="tictactoe-win"> You Won! </h4>
                            : <h4 id="tictactoe-stats">Game Stats:</h4>
                }

                <hr/>

                <div className="tictactoe-gamestats">
                    <p>{`games won: ${gameRecord.gamesWonByPlayer} `}</p>
                    <p>{`games tied: ${gameRecord.gamesTied} `}</p>
                    <p>{`games lost: ${gameRecord.gamesLostByPlayer} `}</p>
                </div>

                <hr/>

                {
                    gameRecord.gameWinner !== null
                        ? <button
                            className="tictactoe-reset-btn"
                            onClick={resetGame}
                        >
                            new game
                        </button>
                        : <></>
                }

            </div>
        </div>
    );
};

export default TicTacToe;
